import React from "react";
import Map from "../GoogleMap";
import { TbChristmasTree } from "react-icons/tb";
import { xmasclose, nyopen } from "../Xmas";

import {
  Container,
  Wrapper,
  Row,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  Column2,
  Column3,
} from "./EnquireElements";

const EnquireSection = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Row>
            <Column1>
              <TextWrapper>
                <TopLine>Enquire</TopLine>
                <Heading>Get in Touch</Heading>
                <Subtitle>
                  At Wheel of Health we care about your future, if we can help
                  you further then please contact us. Our head office is based
                  in Southampton, Hampshire and we are always on the end of the
                  phone or email to help you.
                </Subtitle>
                <Subtitle>
                  Wheelchair and 24-Hour Posture Management is our specialty.
                  Providing solutions and resources you need in support of your
                  independence. From initial contact, to liaison, to final fit,
                  with ongoing support and ongoing reviews to suit you, we’ve
                  got you covered.
                </Subtitle>
                <Subtitle></Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <TextWrapper>
                <TopLine style={{ textAlign: "center" }}>Head</TopLine>
                <Heading style={{ textAlign: "center" }}>Office</Heading>
                <Subtitle>
                  <ul>
                    <li>17 Monks Wood Close</li>
                    <li>Bassett</li>
                    <li>Southampton</li>
                    <li>Hampshire</li>
                    <li>SO16 3TT</li>
                    <li>United Kingdom</li>
                    <li>+44 (0) 23 8076 8583</li>
                  </ul>
                </Subtitle>
              </TextWrapper>
            </Column2>
          </Row>
          <Row>
            <Column1>
              <TextWrapper style={{ textAlign: "center" }}>
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <TopLine style={{ textAlign: "center" }}>Festive</TopLine>
                ) : (
                  <TopLine style={{ textAlign: "center" }}>Business</TopLine>
                )}
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <Heading>Closed</Heading>
                ) : (
                  <Heading>Hours</Heading>
                )}
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <Subtitle>
                    <TbChristmasTree
                      style={{
                        fontSize: "100px",
                      }}
                    />
                  </Subtitle>
                ) : null}
              </TextWrapper>
            </Column1>
            <Column2>
              <TextWrapper>
                {new Date() >= new Date(xmasclose) &&
                new Date() <= new Date(nyopen) ? (
                  <Subtitle>
                    <ul>
                      <li>Please note, the office</li>
                      <li>will be closed this year from:</li>
                      <li>Friday 22nd December 2023</li>
                      <li></li>
                      <li>We will be reopening</li>
                      <li>Wednesday 3rd January 2024</li>
                    </ul>
                  </Subtitle>
                ) : (
                  <Subtitle>
                    <ul>
                      <li>Monday: 09:00 - 17:00hrs</li>
                      <li>Tuesday: 09:00 - 17:00hrs</li>
                      <li>Wednesday: 09:00 - 17:00hrs</li>
                      <li>Thursday: 09:00 - 17:00hrs</li>
                      <li>Friday: 09:00 - 17:00hrs</li>
                      <li>Saturday: CLOSED</li>
                      <li>Sunday: CLOSED</li>
                    </ul>
                  </Subtitle>
                )}
              </TextWrapper>
            </Column2>
          </Row>
        </Wrapper>
      </Container>
      <Container style={{ background: "var(--color-black)" }}>
        <Wrapper style={{ padding: "50px 50px" }}>
          <Row>
            <Column1>
              <TextWrapper>
                <TopLine>Enquire</TopLine>
                <Heading>Our Team</Heading>
              </TextWrapper>
            </Column1>
            <Column3>
              <TextWrapper></TextWrapper>
            </Column3>
          </Row>
        </Wrapper>
      </Container>
      <Map />
    </>
  );
};

export default EnquireSection;
