import React, { useState, useMemo } from "react";
import { animateScroll as scroll } from "react-scroll";
import { ChristmasTree, Firework } from "../Xmas";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  XmasP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "../HeroSection/HeroElements";
import Video from "../../videos/wofhHero1.mp4";
import { xmas, box, nye, nyd } from "../Xmas";

// Extract Christmas message component
const ChristmasMessage = () => (
  <MessageContainer>
    <XmasP>
      <ChristmasTree />
      <MessageText>
        <ImQuotesLeft />
        <span>
          Wishing all our clients, partners, and colleagues all the very best
          for the festive season. We look forward to seeing you in{" "}
          {new Date().getFullYear() + 1}
        </span>
        <ImQuotesRight />
      </MessageText>
    </XmasP>
  </MessageContainer>
);

// Extract New Year message component
const NewYearMessage = () => (
  <MessageContainer>
    <XmasP>
      <Firework />
      <MessageText>
        <ImQuotesLeft />
        <span>
          Wishing all our clients, partners, and colleagues a very happy New
          Year. We look forward to seeing you in {new Date().getFullYear() + 1}
        </span>
        <ImQuotesRight />
      </MessageText>
    </XmasP>
  </MessageContainer>
);

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const currentDate = useMemo(() => new Date(), []);

  const onHover = () => setHover(!hover);
  const toggleHome = () => scroll.scrollToTop();

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>Specialists in 24 Hour Posture Management</HeroH1>
        <HeroP>
          Enabling Solutions That Meet Complex 24-hour Posture Management Needs.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            to="/enquire"
            onClick={toggleHome}
            smooth="1"
            offset={-70}
            duration={400}>
            Contact Us {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
        {currentDate >= new Date(xmas) && currentDate <= new Date(box) && (
          <ChristmasMessage />
        )}
        {currentDate >= new Date(nye) && currentDate <= new Date(nyd) && (
          <NewYearMessage />
        )}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

// Styled components
import styled from "styled-components";

const MessageContainer = styled.div`
  margin-top: 20px;
  font-size: 32px;
  text-align: center;
`;

const MessageText = styled.h4`
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-flex;
    align-items: center;
  }

  svg {
    margin: 0 2px;
    padding-bottom: 10px;
  }
`;
