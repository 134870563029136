import React from "react";
import {
  ClientContainer,
  ClientTopLine,
  ClientHeader,
  ClientSubHeading,
  ClientWrapper,
  ClientCard,
  ClientH2,
  ClientP,
  ClientImgLW,
  ClientImgSW,
  ClientImgSR,
  ClientImgCA,
  ClientImgDB,
  ClientImgJT,
  ClientImgLL,
  ClientImgEW,
  ClientImgLA,
  ClientImgTC,
  ClientImgAJ,
  ClientImgJM,
  ClientImgMM,
  ClientImgLO,
  ClientImgJR,
  ClientImgVT,
  ClientImgCE,
  ClientImgEM,
  ClientImgVO,
  ClientImgXS,
  ClientImgLC,
  ClientImgJRW,
  ClientImgLOF,
  ClientImgRS,
  ClientImgLS,
  ClientImgSCS,
} from "./ClientElements";

const Client = () => {
  return (
    <>
      <ClientContainer id="Client">
        <ClientTopLine>Discover</ClientTopLine>
        <ClientHeader>Our Team</ClientHeader>
        <ClientSubHeading>Senior Management Team</ClientSubHeading>
        <ClientWrapper>
          <ClientCard>
            <ClientImgLW />
            <ClientH2>Lucy Weech</ClientH2>
            <ClientP>Clinical Director</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgSW />
            <ClientH2>Simon Weech</ClientH2>
            <ClientP>Director</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgSR />
            <ClientH2>Samantha Richardson</ClientH2>
            <ClientP>Administration Manager</ClientP>
          </ClientCard>
        </ClientWrapper>
        <ClientHeader></ClientHeader>
        <ClientSubHeading>Team Leaders</ClientSubHeading>
        <ClientWrapper>
          <ClientCard>
            <ClientImgCA />
            <ClientH2>Claire Ashton</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgDB />
            <ClientH2>Deborah Bowditch</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgJT />
            <ClientH2>Julian Throssell</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgLL />
            <ClientH2>Louise Lloyd</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
        </ClientWrapper>
        <ClientHeader></ClientHeader>
        <ClientSubHeading>Senior Associates</ClientSubHeading>
        <ClientWrapper>
          <ClientCard>
            <ClientImgEW />
            <ClientH2>Emma Wakeling</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgLA />
            <ClientH2>Lisa Alexanda</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgTC />
            <ClientH2>Tania Carrigan</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgAJ />
            <ClientH2>Amanda Jackson</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgJM />
            <ClientH2>Jo McConnell</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgMM />
            <ClientH2>Margaret McEvoy</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgLO />
            <ClientH2>Lauren Osborne</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgJR />
            <ClientH2>Jenny Rolfe</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgVT />
            <ClientH2>Vicky Thompson</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgEM />
            <ClientH2>Elizabeth McWilliams</ClientH2>
            <ClientP>Physiotherapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
        </ClientWrapper>
        <ClientHeader></ClientHeader>
        <ClientSubHeading>Associates</ClientSubHeading>
        <ClientWrapper>
          <ClientCard>
            <ClientImgCE />
            <ClientH2>Caroline Eriksson</ClientH2>
            <ClientP>Physiotherapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgVO />
            <ClientH2>Victoria Ost</ClientH2>
            <ClientP>Associate</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgXS />
            <ClientH2>Xiaoyu Shu</ClientH2>
            <ClientP>Physiotherapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgLC />
            <ClientH2>Lesley Clarridge</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgJRW />
            <ClientH2>Jennifer Wright</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgLOF />
            <ClientH2>Laura O'Flaherty</ClientH2>
            <ClientP>Occupational Therapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgRS />
            <ClientH2>Rakesh Shukla</ClientH2>
            <ClientP>Rehabilitation Engineer</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgLS />
            <ClientH2>Lauren Southern</ClientH2>
            <ClientP>Physiotherapist</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
          <ClientCard>
            <ClientImgSCS />
            <ClientH2>Shelley Chatter-Singh</ClientH2>
            <ClientP>Physiotherapistt</ClientP>
            <ClientP>CV available</ClientP>
          </ClientCard>
        </ClientWrapper>
      </ClientContainer>
    </>
  );
};

export default Client;
