import { useState, useMemo, useCallback, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer
} from "@react-google-maps/api";

import Places from "./places";
import Distance from "./distance";

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;

const containerStyle = {
  width: "100vw",
  height: "100vh"
};

export const associates = [
  {
    name: "Wheel of Health Limited",
    loc: "lat: 50.946803, lng: -1.3934312"
  }
];

export default function Map() {
  const [office, setOffice] = useState<LatLngLiteral>();
  
  const wofh = {
    lat: 50.9097,
    lng: -1.4044
};
const AJ = {
  lat: 54.6861,
  lng: -1.2126 // Hartlepool
};
const CA = {
  lat: 53.4098,
  lng: -2.1576 // Stockport
};
const CE = {
  lat: 51.4452,
  lng: -0.2065 // Southwest London
};
const DB = {
  lat: 51.4816,
  lng: -3.1791 // Cardiff
};
const EM = {
  lat: 50.8278,
  lng: -0.1687 // Hove
};
const EW = {
  lat: 50.7156,
  lng: -3.5309 // Devon
};
const JM = {
  lat: 52.1920,
  lng: -2.2200 // Worcester
};
const JR = {
  lat: 51.7520,
  lng: -1.2577 // Oxford
};
const JRW = {
  lat: 54.5689,
  lng: -1.3187 // Stockton on Tees
};
const JT = {
  lat: 51.5074,
  lng: -0.1278 // London
};
const LA = {
  lat: 50.8225,
  lng: -0.1372 // Brighton
};
const LC = {
  lat: 51.7580,
  lng: -1.2633 // Oxfordshire
};
const LL = {
  lat: 52.4862,
  lng: -1.8904 // Birmingham
};
const LO = {
  lat: 51.7717,
  lng: -0.2092 // Hertfordshire
};
const LOF = {
  lat: 53.4129,
  lng: -8.2439 // Ireland
};
const LS = {
  lat: 53.9900,
  lng: -1.5393 // Harrogate
};
const MM = {
  lat: 54.7877,
  lng: -6.4923 // Northern Ireland
};
const RS = {
  lat: 51.3148,
  lng: -0.5591 // Surrey
};
const SCS = {
  lat: 50.9413,
  lng: 0.1320 // East Sussex
};
const SR = {
  lat: 50.9413,
  lng: 0.1320 // East Sussex
};
const TC = {
  lat: 51.2362,
  lng: -0.5704 // Guildford
};
const VO = {
  lat: 52.6369,
  lng: -1.1398 // Leicester
};
const VT = {
  lat: 52.2405,
  lng: -0.9027 // Northampton
};
const XS = {
  lat: 51.5112,
  lng: -0.2983 // West London
};
 
  const mapRef = useRef<GoogleMap>();
  const center = useMemo<LatLngLiteral>(
    () => ({ lat: 54.00366, lng: -2.547855 }),
    []
  );
  const options = useMemo<MapOptions>(
    () => ({
      mapId: "d2f54d8ae91809da",
      disableDefaultUI: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      clickableIcons: false
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  return (
    <>
      {/* <Places
            setOffice={(position) => {
              setOffice(position);
              mapRef.current?.panTo(position);
            }}
          /> */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5.6}
        options={options}
        onLoad={onLoad}
      >
        <Marker position={wofh} />
        <Marker position={AJ} />
        <Marker position={CA} />
        <Marker position={CE} />
        <Marker position={DB} />
        <Marker position={EM} />
        <Marker position={EW} />
        <Marker position={JM} />
        <Marker position={JR} />
        <Marker position={JRW} />
        <Marker position={JT} />
        <Marker position={LA} />
        <Marker position={LC} />
        <Marker position={LL} />
        <Marker position={LO} />
        <Marker position={LOF} />
        <Marker position={LS} />
        <Marker position={MM} />
        <Marker position={RS} />
        <Marker position={SCS} />
        <Marker position={SR} />
        <Marker position={TC} />
        <Marker position={VO} />
        <Marker position={VT} />
        <Marker position={XS} />
      </GoogleMap>
    </>
  );
}

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true
};
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.05,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A"
};
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#FBC02D",
  fillColor: "#FBC02D"
};
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#FF5252",
  fillColor: "#FF5252"
};

const generateHouses = (position: LatLngLiteral) => {
  const _houses: Array<LatLngLiteral> = [];
  for (let i = 0; i < 100; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2;
    _houses.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction
    });
  }
  return _houses;
};
